/* stylelint-disable */
@import "styles/variable";
@import "styles/mixins";
@import "./global.module";
// @import "./index";

// $max-content-width: 1030px;
$content-width: 732px;

.allContent {
  background-color: #fff;
  min-width: $min-content-width;
  padding-bottom: 24px;
}

.pageTitle {
  margin: 0 auto;
  margin-top: 10px;
  padding: 16px;
  padding-bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #000;

  @include contentWidth;
}

.header,
.content {
  margin: 0 auto;

  @include contentWidth;
}

.contentWrap {
  margin: 0 auto;
  @include min1440px;

  .content {
    margin: 0 auto;
    gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    background: #f8f9fa;
    padding: 16px;

    @include contentWidth;
  }
}

.content_defaultBg,
.content_whiteBg {
  background: #f8f9fa;
  margin: 0 auto;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  @include contentWidth;
}

.content_whiteBg {
  background-color: #fff;
}

.paginationWarp {
  padding-bottom: 10px;
}

.headerWarp {
  margin: 0 auto;
  position: relative;
  margin-left: 48px;
  margin-right: 48px;
  background-color: rgb(237, 246, 254);
  border-radius: 12px;

  .headerBannerWarp {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .bannerBox {
      max-width: 1624px;
      min-width: 1104px;
      margin: 0 auto;
      position: relative;

      .bannerImg {
        position: absolute;
        width: auto;
        height: 400px;
        top: -44px;
        left: 45px;
        z-index: 0;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding-top: 26px;
  padding-bottom: 26px;
  background-image: url("../assets/header-bg.png");
  background-size: 255px;
  background-position-x: 80px;
  background-position-y: -53px;
  background-repeat: no-repeat;
  @include contentWidth;

  .theBestTitleInPage {
    padding-left: 8px;
    margin: 0 auto;

    h1 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      color: #000;
    }
  }

  .search {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;
    // z-index: 10;
    padding-left: 180px;
    padding-right: 180px;

    @include max1440px {
      padding-left: 210px;
      padding-right: 210px;
    }
  }
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;

  .fields {
    display: flex;
    gap: 8px;
    align-items: center;

    @media (max-width: 1360px) {
      gap: 16px;
    }
  }

  .line {
    width: 1px;
    height: 24px;
    background: #d6e6ff;
  }
}

.singleField {
  display: flex;
  align-items: center;
  min-width: 100px;
  max-width: 200px;
  position: relative;
  z-index: 1;

  .label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #7d7d7d;
    text-align: center;
  }

  .singleOverrideSelect:global(.ant-select.ant-cascader) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%),
      rgba(255, 255, 255, 0.1);
  }
}

.multiple {
  display: flex;
  gap: 8px;
  align-items: center;
}

.switch {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  // :global {
  //   .ant-switch.ant-switch-checked {
  //     border-radius: 6px;
  //     background: #1C70EE;
  //   }
  //   .ant-switch {
  //     border-radius: 6px;
  //     margin-left: 23px;

  //     .ant-switch-handle {
  //       border-radius: 5px;
  //       background: #ffffff;
  //     }
  //   }
  // }
}

.clearAll {
  display: flex;
  height: 44px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 12px;
  background: #fff;

  border: 1px solid rgba(0, 102, 255, 0.1);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7)) 100%,
    rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 8px 0px rgba(0, 102, 255, 0.05);
  flex: 0 0 44px;
  cursor: pointer;

  svg {
    vertical-align: bottom;
  }

  &:hover {
    backdrop-filter: blur(3px);
    border: 1px solid #3385ff !important;
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2) !important;
  }
}

// TODO main style
.tableContainer {
  width: fit-content;
  width: 100%;
  padding-bottom: 50px;
  padding-left: 0;
  background: transparent;
  border-radius: 10px;

  @include sm {
    display: none;
  }

  .jobContainer {
    margin-bottom: 15px;
  }
}

.loadingSke {
  width: 100%;
  height: 145px;
  padding: 14px 24px 0;
  margin-bottom: 16px;
  margin-left: 0;
  background: #fff;
  border-radius: 10px;

  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.histories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 257px;
  background: #fff;
  border: 1px solid #e5e6eb;
  border-radius: 8px;

  .title {
    width: 100%;
    padding: 20px 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    color: #1d2129;
    letter-spacing: 0.15px;
    border-bottom: 1px solid #e5e6eb;
  }

  .historiesList {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 20px;
  }

  a {
    display: block;
  }

  .item {
    --color: #86909c;

    width: 100%;
    padding: 8px 8px 8px 28px;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: var(--color);
    text-overflow: ellipsis;
    letter-spacing: 0.105px;
    white-space: nowrap;

    &:hover {
      --color: #1d2129;

      background: #f4f9ff;
    }
  }
}

.jobCard {
  width: 100%;
  min-height: 166px;
  position: relative;
  padding-right: 0;

  .urgent {
    display: flex;
    padding: 1px 3px 2px 3px;
    justify-content: center;
    align-items: center;

    color: #fffefe;
    font-size: 10px;
    font-family: "Product Sans";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.075px;

    border-radius: 4px;
    background: linear-gradient(128deg, #f64a88 0%, #f56a6a 100%);
    box-shadow: 0px 4px 13px 0px rgba(255, 60, 60, 0.1);

    margin-right: 8px;
  }

  .remote {
    background: linear-gradient(101deg, orange 6.03%, #ffcd7e 92.76%);
  }

  .container {
    width: calc(100%);
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.3s ease-in;
    cursor: pointer;
    border-radius: 8px;
    background: #fff;

    .topContainer {
      width: 100%;
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
        width: 477px;
        height: 100%;

        .titleContainer {
          width: 100%;
          height: 23px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          align-items: center;

          .title {
            --color: #1d2129;
            color: var(--color);

            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.128px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              --color: #136fd3;
            }

            p {
              margin-bottom: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .salary {
          color: #2378e5;
          text-align: right;
          font-style: normal;
          letter-spacing: 0.113px;
          width: fit-content;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;

          span {
            color: #2378e5;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }

        .labelContainer {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          svg {
            margin: 0 0 3px;
          }

          .label {
            color: #7d7d7d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin: 0 7px;
          }
        }

        .recruiterContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 16px;
          width: 100%;
          padding-top: 12px;
          border-top: 1px solid #f6f6f6;

          &_main {
            display: flex;
            flex-direction: row;
          }

          .imgContainer {
            position: relative;
            overflow: visible;

            img {
              border: 1px solid #f6f6f6;
              overflow: hidden;
              border-radius: 12px;
              margin-right: 6px;
              border-bottom-left-radius: 0px;
              vertical-align: bottom;
            }

            .defaultAvatar {
              border: 1px solid #34c759;
            }

            .isOnline {
              width: 9px;
              height: 9px;
              border-radius: 4.5px;
              border: #fff solid 1px;
              background: #0ebd5c;
              position: absolute;
              bottom: 2px;
              right: 3px;
            }
          }

          .recruiter {
            .info {
              max-width: 338px;

              padding: 2px 0px;
              margin-left: 4px;

              color: #515151;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              height: 24px;
              line-height: 24px;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              display: flex;
              gap: 4px;
              justify-content: center;
              align-items: center;

              &.isHover {
                display: none;
              }

              span {
                color: #7d7d7d;
              }
            }

            .button {
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              height: auto !important;
              // line-height: 25px;
              text-transform: capitalize;
              margin-left: 4px;
              margin-top: -1px;
              padding: 4px 16px;

              border-radius: 4px;
              background: #2378e5 !important;
              box-shadow: 0px 4px 10px 0px rgba(35, 120, 229, 0.15);
              display: none;
              min-width: 80px;

              &::after {
                content: " ";
                background: #2378e5;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 8px;
                left: -3px;
                transform: rotate(45deg);
              }

              &.isHover {
                display: flex;
                height: 25px !important;
                cursor: pointer;
              }

              :global {
                .MuiCircularProgress-root {
                  color: #ffffff;
                }
              }
            }

            // height: 21px;
          }

          .online {
            border-radius: 4px;
            background: #f2f9f3;
            padding: 7px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            line-height: 25px;
            color: #4caf50;
            margin-left: 8px;
            font-size: 12px;

            &:before {
              content: " ";
              background: #4caf50;
              border-radius: 3px;
              border: 1px solid #4caf50;
              width: 6px;
              height: 6px;
              margin-right: 4px;
            }

            :first-of-type {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              display: flex;
              align-items: center;
              letter-spacing: 0.0075em;
              color: #ffffff;
            }
          }
        }
      }

      .right {
        height: 100%;
        min-width: 175px;

        .salary {
          color: #2378e5;
          text-align: right;
          font-style: normal;
          letter-spacing: 0.113px;
          width: fit-content;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;

          span {
            color: #2378e5;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }

        .companyUnderTags {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-top: 16px;

          .tag {
            padding: 2px 12px;
            align-self: stretch;
            border-radius: 16px;
            background: rgba(245, 247, 251, 0.8);
            color: #515151;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            max-width: 175px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
          }
        }
      }
    }

    .bottomContainer {
      height: 38px;
      width: 100%;
      position: relative;
      border-radius: 10px 0px 10px 0px;

      &::before {
        content: " ";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 1;
        background: linear-gradient(75deg, #f4faff 0%, #fff 100%);
        // background: linear-gradient(90deg, #F2F9FF 0%, #FFFFFF 100%);
      }

      padding-left: 24px;
      padding-right: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .skills {
        width: 478px;
        height: 15px;

        color: #4e5969;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.09px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 10;
      }

      .benefits {
        height: 15px;
        width: 175px;
        margin-left: 48px;

        color: #4e5969;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.09px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 10;
      }
    }
  }

  .popupDetail {
    // --opacity:1;
    position: absolute;
    top: 0px;
    right: 30px;
    width: 415px;
    // height: 395px;
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 10px 10px 10px #ddd;
    z-index: 21;

    &.hide {
      display: none;
      // --opacity: 0;
    }

    .popTop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 145px;
      overflow: hidden;
      padding: 13px 16px;
      background: linear-gradient(354deg, #fff 0%, #b7dbff 100%);

      &Left {
        flex: 1;

        .title {
          font-family: "Product Sans";
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          letter-spacing: 0.02em;
          color: #353535;
          width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .secondLabel {
          font-family: "Product Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.0075em;
          color: #707070;
          width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 8px;
        }

        .save {
          background: transparent;
          box-shadow: none;
          border: 1px solid #136fd3;
          height: 30px;
          border-radius: 4px;
          color: #136fd3;
          margin-top: 45px;
        }
      }

      &Right {
        &_wrapper {
          padding: 6px;
          background: #fff;
        }
      }
    }

    .popContent {
      padding: 14px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .desTitle {
        align-self: flex-start;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0075em;
        color: #353535;
      }

      .detail::-webkit-scrollbar {
        width: 5px;
      }

      .detail::-webkit-scrollbar-thumb {
        background-color: #e4e4e4;
        border-radius: 5px;
      }

      .detail::-webkit-scrollbar-track {
        background: white;
        border-radius: 5px;
      }

      .detail {
        align-self: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #353535;
        max-height: 213px;
        overflow: auto;
        margin-top: 8px;
        width: 100%;
      }
    }
  }

  .leastCompany {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 12px;

    .logo {
      border-radius: 2px;
    }

    .name {
      color: #121212;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .jobLocationAndType {
    display: flex;
    align-items: center;

    .tag {
      padding: 0px 8px;
      border-radius: 4px;
      background: #eef5ff;
      color: #3a90ff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      max-width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}

.qrCode {
  @include sm {
    display: none;
  }

  position: fixed;
  right: 0;
  top: 254px;

  width: 118px;
  // height: 164px;
  margin-bottom: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  border-radius: 8px 0px 0px 8px;
  background: #fff;
  box-shadow: 0px 0px 41px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;

  &Text {
    color: #2378e5;
    text-align: center;
    font-size: 14px;
    font-family: Product Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.105px;

    display: flex;
    flex-direction: column;
  }
}

.cardLoading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 16px;
  height: 100vh;
  position: sticky;
  top: 0px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cardLoadingContent {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  transform: translateY(-130px);
}

.cardLoadingContent svg {
  animation: spin 1s linear infinite;
}

.cardNetworkError {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 16px;
}

.cardNetworkErrorContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.cardNetworkErrorIcon {
  width: 180px;
  height: 180px;
  background: #dfdfdf;
}

.cardNetworkErrorText {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #000;
}

.cardNetworkErrorButton {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 12px 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #06f;
  background-color: #fff;
  border: 1px solid #06f;
  border-radius: 8px;
}

.cardNetworkErrorButton:hover {
  color: #0055d4;
  border-color: #0055d4;
}

.contentLeft {
  width: 518px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contentRight {
  height: calc(100vh - 160px);
  position: sticky;
  top: 160px;
  overflow-y: auto;
  flex: 1;
  transition: all 0.3s ease-in-out;
  border-radius: 16px;

  :global {
    .override-main-section {
      height: unset;
    }
  }
}

.stickySearch {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 8px 10px 16px 7px;
  display: none;
  box-shadow: 0px 8px 8px -2px rgba(187, 197, 206, 0.15);

  :global {
    .wrapperSelectMut {
      .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #ebeef0;
        box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
        background: #fff;
      }
    }

    .overrideCascaderNew {
      .ant-select.ant-cascader {
        border: 1px solid #ebeef0;
        box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
        background: #fff;
      }
    }
  }

  .stickySearch_warp {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    @include contentWidth;
    margin: 0 auto;
    // transform: translateX(30px);

    .stickySearch_hide {
      display: flex;
      align-items: center;
      gap: 8px;

      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      cursor: pointer;
    }

    .stickySearch_hide_active {
      color: #1c70ee;

      svg {
        path {
          fill: #1c70ee;
        }
      }
    }
  }

  .stickySearch_warp_show {
    margin-bottom: 18px;
    position: relative;
    z-index: 2;
  }

  :global {
    .yolo-searchArea-wrapper {
      width: 900px;
      border-radius: 16px;
      border: 1.2px solid #1c70ee;
      background: rgba(255, 255, 255, 0.7);
      padding-right: 10px;
      box-shadow: unset;

      .yolo-search-button {
        width: 40px;
        height: 40px;
        border-radius: 10px;

        svg + span {
          display: none;
        }
      }

      .yolo-autocomplete-select {
        .ant-select {
          .ant-select-selector {
            padding-left: 19px !important;
          }
        }
      }
    }

    .yolo-filter-warp {
      justify-content: center;
    }
  }
}

.stickySearchDisplay {
  display: block;
  // transform: translateY(0px);
  transition: 0.3s;
  animation: showTrans 0.25s ease-in-out both;
}

.moreFiled {
  height: 44px;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid rgba(0, 102, 255, 0.1);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7)) 100%,
    rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 8px 0px rgba(0, 102, 255, 0.05);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    backdrop-filter: blur(3px);
    border: 1px solid #3385ff !important;
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2) !important;
  }
}

.placeholderInSearch {
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.field_count {
  padding: 4px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #1c70ee;
  text-align: center;
  background: rgba(0, 102, 255, 0.1);
  border-radius: 10px;
  line-height: 12px;
}

@keyframes showTrans {
  0% {
    transform: translate(0, -25px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.otherContent {
  max-width: $max-content-width;
  min-width: $min-content-width;
  margin: 0 auto;
  display: flex;
  gap: 16px;
  margin-top: 20px;

  .otherMore_card {
    flex: 1;
    display: flex;
    padding: 24px 16px;
    padding-bottom: 16px;
    flex-direction: column;
    gap: 12px;
    border-radius: 16px;
    background: #f8f9fa;

    > h2 {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      color: #000;
      margin-bottom: 4px;
    }

    .otherMore_seeMore {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;

      div {
        padding: 12px 32px;
        gap: 6px;
        border-radius: 8px;
        background: #fff;

        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .otherMore_hangBox {
    width: 324px;
    display: flex;
    flex-direction: column;

    .otherMore_ad {
      flex: 1 1;
      height: 310px;
      border-radius: 17px;
      background: linear-gradient(
          319deg,
          rgba(126, 242, 255, 0.25) -1.36%,
          rgba(152, 245, 255, 0) 45.92%
        ),
        #d7eaff;
    }
  }
}

.filtersInHeader {
  .moreFiled {
    border: 1px solid #ebeef0;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  .clearAll {
    border: 1px solid #ebeef0;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }
}

.listRow {
  .contentLeft {
    width: 100%;
    flex-flow: row wrap;

    :global {
      .yolo-technology-pagination {
        margin: 0 auto;
      }

      .yolo-technology-jobCard {
        width: calc(50% - 6px);
      }
    }
  }
  .contentRight {
    display: none;
  }
}
