/* stylelint-disable */

$max-content-width: 1440px;
$min-content-width: 1200px;

$max-content-around-margin: 48px;

@mixin contentWidth {
  max-width: 1344px;
  min-width: 1104px;
}

// Large devices
@mixin max1440px {
  @media (min-width: 1440px) {
    width: 1344px;
    @content;
  }
}

@mixin min1440px {
  @media (max-width: 1440px) {
    margin-left: 48px;
    margin-right: 48px;
    @content;
  }
}

// Medium devices
@mixin max1280px {
  @media (min-width: 1280px) {
    width: 1184px;
    @content;
  }
}

// Medium small devices (Ipad)
@mixin max1200px {
  @media (min-width: 1200px) {
    width: 1104px;
    @content;
  }
}
